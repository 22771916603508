import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useData } from '../DataProvider';
import WatchListAccordion from './WatchListAccordion';
import ShareDialog from '../ShareDialog/ShareDialog';
import {
  getAttributeById,
  getCollectionById,
  getDesignlineById,
  getMaterialById,
  isAllWeather,
  isCBA,
  isUV,
  replaceSpecialChars,
} from '../../util/util';
import { openInquiryDialog } from '../Subjects/subjects';
import cbaImg from '../../assets/collections/_CBA_silber4C.png';
import allWeatherImg from '../../assets/collections/All Weather.png';
// import IpcImg from '../../assets/collections/IPC finish.png';
import uvSafetyImg from '../../assets/collections/UV Schutz 2.5 Prozent V2.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    textAlign: 'left',
  },

  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
    height: 82,
  },
  row: {
    display: 'flex',
  },
  marginBottom: {
    marginBottom: '5',
  },

  card: {
    minWidth: 600,
    padding: '20px',
    marginBottom: 10,
    background: '#F8F8F8',
    fontSize: '14px',
  },
}));

export default function FabricItemInfoTab({ cardItem, isWatchList = false, hotspotId, isGardenFurniture }) {
  const { removePaginationCardById, jsonData, selectColor, watchMap, updateCard, userData } = useData();
  const classes = useStyles();
  const isOnWatchMap = !!watchMap.get(cardItem.id);

  const fabricNameItem = replaceSpecialChars(cardItem.post_title);
  const src = `${process.env.REACT_APP_BASE_URL}/wp-content/uploads/stoffdetailansicht/${fabricNameItem}/hd.jpg`;

  const { t } = useTranslation();
  const [isFavorite, setFavorite] = useState(isOnWatchMap);
  const [title, setCollectionTitle] = useState('');

  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;

  const setItemFavorite = () => {
    setFavorite(!isFavorite);

    const newCardInfo = {
      subgroupId: hotspotId,
      isFavorite: !isFavorite,
      product: cardItem,
    };
    updateCard(cardItem.id, newCardInfo);
  };

  const deleteCard = (cardItem_) => {
    removePaginationCardById(cardItem_.id);
  };

  useEffect(() => {
    if (isGardenFurniture) {
      const collections = cardItem.collection
        .split(',')
        .map((item) => getCollectionById(item, jsonData).post_title)
        .join(', ');
      setCollectionTitle(collections);
    } else {
      const collection = getCollectionById(cardItem.collection, jsonData).post_title;
      setCollectionTitle(collection);
    }
  }, [cardItem, isGardenFurniture, jsonData]);

  function WatchListButtons() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ textTransform: 'capitalize' }} type="button" onClick={() => deleteCard(cardItem)}>
          {t('remove')}
        </Button>
        {userData.data.data.userData.application_enable_contact_email === 'true' ? (
          <Button style={{ textTransform: 'capitalize' }} type="button" onClick={() => openInquiryDialog(cardItem.id)}>
            {t('request')}
          </Button>
        ) : (
          ''
        )}
        <ShareDialog cardItem={cardItem} />
      </div>
    );
  }

  function FabricInfoButtons() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ShareDialog cardItem={cardItem} />
        <IconButton aria-label="like" style={{ color: selectColor }} onClick={setItemFavorite}>
          {isFavorite ? (
            <FavoriteIcon style={{ color: selectColor }} />
          ) : (
            <FavoriteBorderIcon style={{ color: selectColor }} />
          )}
        </IconButton>
      </div>
    );
  }

  const getAttributes = () => {
    if (cardItem.attribute_set) {
      const attributes = cardItem.attribute_set
        .split(',')
        .filter((elem) => elem !== '')
        .map((elem) => `${getAttributeById(elem, jsonData).post_title}`);
      return attributes;
    }
    return '';
  };

  const fabricNumber =
    cardItem.customer_set_alt_article_number && cardItem.customer_set_alt_article_number.length > 3
      ? cardItem.customer_set_alt_article_number
      : cardItem.post_title;

  const concatFabricNumber =
    fabricNumber.length === 6 ? `${fabricNumber.substring(0, 3)} ${fabricNumber.substring(3, 6)}` : fabricNumber;

  const fabricName =
    cardItem.customer_set_alt_fabric_name && cardItem.customer_set_alt_fabric_name.length > 3
      ? cardItem.customer_set_alt_fabric_name
      : cardItem.fabric_name;

  const showInchOrCmRapport = () => {
    if (jsonData.customer.fabric_unit === 'cm' && cardItem.fabric_rapport_width !== '') {
      return true;
    }
    if (jsonData.customer.fabric_unit === 'in' && cardItem.fabric_rapport_width_inch !== '') {
      return true;
    }
    return false;
  };

  return (
    <Card className={classes.card} style={{ minWidth: isPhone ? 275 : 600 }}>
      <div className={classes.root}>
        <CardMedia className={classes.cover} image={src} title="Stofffilter" />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6">
              <b> {concatFabricNumber}</b>
            </Typography>
            <Typography component="h6">{fabricName}</Typography>
          </CardContent>
        </div>
        {!isPhone && (
          <div style={{ marginLeft: '10%' }}>
            {isCBA(cardItem.collection) && <img src={cbaImg} alt="cbaImg" width="50" style={{ marginRight: 10 }} />}
            {isUV(cardItem.collection) && (
              <img src={uvSafetyImg} alt="uvSafetyImg" width="100" style={{ marginRight: 10 }} />
            )}
            {isAllWeather(cardItem.collection) && (
              <img src={allWeatherImg} alt="allWeatherImg" width="50" style={{ marginRight: 10 }} />
            )}
          </div>
        )}
      </div>
      {isPhone && (
        <div style={{ marginTop: '10%', marginBottom: '10%' }}>
          {isCBA(cardItem.collection) && <img src={cbaImg} alt="cbaImg" width="50" style={{ marginRight: 10 }} />}
          {isUV(cardItem.collection) && (
            <img src={uvSafetyImg} alt="uvSafetyImg" width="100" style={{ marginRight: 10 }} />
          )}
          {isAllWeather(cardItem.collection) && (
            <img src={allWeatherImg} alt="allWeatherImg" width="50" style={{ marginRight: 10 }} />
          )}
        </div>
      )}
      <Grid container style={{ textAlign: 'left' }}>
        {cardItem.collection !== '' && (
          <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={6} sm={3} md={3}>
            <b>{t('quality')}:</b>
          </Grid>
        )}
        <Grid classname={classes.marginBottom} style={{ marginLeft: 10 }} item xs={5} sm={8} md={8}>
          {cardItem.collection !== '' ? title : ''}
        </Grid>

        {!isGardenFurniture && cardItem.design_line !== '' && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={6} sm={3} md={3}>
              <b>{t('designline')}:</b>
            </Grid>
            <Grid classname={classes.marginBottom} style={{ marginLeft: 10 }} item xs={5} sm={8} md={8}>
              {cardItem.design_line !== '' ? getDesignlineById(cardItem.design_line, jsonData).post_title : ''}
            </Grid>
          </>
        )}
        {cardItem.material !== '' && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={6} sm={3} md={3}>
              <b>{t('Material')}:</b>
            </Grid>
            <Grid classname={classes.marginBottom} style={{ marginLeft: 10 }} item xs={5} sm={8} md={8}>
              {cardItem.material !== '' ? getMaterialById(cardItem.material, jsonData).post_title : ''}
            </Grid>
          </>
        )}
        {cardItem.attribute_set.replaceAll(',', '') !== '' && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={6} sm={3} md={3}>
              <b>{t('special_qual')}:</b>
            </Grid>
            <Grid
              classname={classes.marginBottom}
              style={{ marginLeft: 10, alignSelf: 'flex-end' }}
              item
              xs={5}
              sm={8}
              md={8}
            >
              {cardItem.attribute_set !== ''
                ? getAttributes()
                    .join(', ')
                    .replace(/, ([^,]*)$/, ', $1')
                : ''}
            </Grid>
          </>
        )}
        {showInchOrCmRapport() && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={6} sm={3} md={3}>
              <b>{t('rapport')}:</b>
            </Grid>
            <Grid classname={classes.marginBottom} item xs={5} sm={8} md={8}>
              {jsonData.customer.fabric_unit === 'cm'
                ? `${cardItem.fabric_rapport_width} ${jsonData.customer.fabric_unit}`
                : `${cardItem.fabric_rapport_width_inch} ${jsonData.customer.fabric_unit}`}
            </Grid>
          </>
        )}
      </Grid>

      {isWatchList ? <WatchListButtons /> : <FabricInfoButtons />}
      <WatchListAccordion card={cardItem} />
    </Card>
  );
}

FabricItemInfoTab.propTypes = {
  cardItem: PropTypes.instanceOf(Object).isRequired,
  hotspotId: PropTypes.string,
};

FabricItemInfoTab.defaultProps = {
  hotspotId: 0,
};
