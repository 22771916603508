import * as React from 'react';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from 'react-responsive';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import ShareTabPane from './ShareTabPane';
import ScenePagination from '../Carousel/Thumbs/ScenePagination';
import { checkMobile, checkTablet } from '../../util/util';
import { useData } from '../DataProvider';

export default function ShareDialog({ cardItem }) {
  let numberOfTodos = 5;
  if (checkTablet) {
    numberOfTodos = 3;
  }
  if (checkMobile) numberOfTodos = 2;
  const { selectedSceneIndex, allScenes } = useData();

  const [open, setOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const maxWidth = isTabletOrMobile ? 'sm' : 'md'; // sm, md, lg
  const { t } = useTranslation();
  const [localCardId, setLocalCardId] = useState(allScenes[selectedSceneIndex].scene.id);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  return (
    <>
      <Button style={{ textTransform: 'capitalize' }} type="button" onClick={handleClickOpen}>
        {t('share_fabric')}
      </Button>
      <Dialog maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle style={{ textTransform: 'uppercase' }} id="max-width-dialog-title">
          {t('share_fabric')}
        </DialogTitle>
        <DialogContent>
          <div>{t('select_scene_mail')}</div>
          <div style={{ margin: 20 }}>
            <ScenePagination
              todosPerPage={numberOfTodos}
              shouldUpdateScene={false}
              localCardId={localCardId}
              setLocalCardId={setLocalCardId}
            />
          </div>
          <ShareTabPane cardItem={cardItem} localCardId={localCardId} />
        </DialogContent>
        <DialogActions>
          <IconButton aria-label="close" style={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
