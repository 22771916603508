import React, { useEffect, useRef, useState } from 'react';
import Unity, { UnityContext } from 'react-unity-webgl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../components/navigation/NavigationBar';
import Video from '../assets/video/DS_Loading_Loop_V2.mp4';

const unityContext = new UnityContext({
  loaderUrl: `${window.location.origin}/Build/sattler_schattensimulator.loader.js`,
  dataUrl: `${window.location.origin}/Build/sattler_schattensimulator.data`,
  frameworkUrl: `${window.location.origin}/Build/sattler_schattensimulator.framework.js`,
  codeUrl: `${window.location.origin}/Build/sattler_schattensimulator.wasm`,
});
// eslint-disable-next-line func-names
window.alert = function () {};

const ShadowSimulator = () => {
  const [loading, setLoading] = useState(0);
  const { t } = useTranslation();

  unityContext.on('progress', (progression) => {
    setLoading(progression);
  });

  function handleOnClickFullscreen() {
    unityContext.on('quitted', () => {});
  }
  const unityRef = useRef(null);

  useEffect(() => {
    unityContext.on('UnityNotification', (message) => {
      // eslint-disable-next-line no-console
      console.log(message);
      const language = new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng');
      unityContext.send('ScriptObject', 'UpdateLocalization', language);
    });
  });

  return (
    <Container disableGutters maxWidth="lg">
      <NavigationBar unityRef={unityRef} />
      <Unity ref={unityRef} unityContext={unityContext} className="unity-canvas" />
      {loading !== 1 && (
        <Box width="1230" mr={1}>
          <Box top="5%" left={0} bottom={0} right={0} position="absolute" display="flex" justifyContent="center">
            <div>
              <video
                style={{ height: 600, border: '3px solid #f3f3f3' }}
                loop
                autostart
                autoPlay
                src={Video}
                type="video/mp4"
              >
                <track kind="captions" />
              </video>
            </div>
          </Box>
        </Box>
      )}
      {loading === 1 && <Button onClick={() => handleOnClickFullscreen()}>{t('fullscreen')}</Button>}
    </Container>
  );
};
export default ShadowSimulator;
