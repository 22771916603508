import Container from '@material-ui/core/Container';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, DialogContent, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import api from '../../api/api';
import { useData } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function AdminDialog() {
  const classes = useStyles();
  const { t } = useTranslation();

  const adminLogin = new URLSearchParams(window.location.search).get('adminLogin');
  const { queryParams } = useData();

  const [loginState, setLoginState] = useState(false);
  const [loginError, setError] = useState(false);

  const maxWidth = 'lg';
  const [open, setOpen] = useState(adminLogin);

  const [loginData, setLoginData] = useState({
    emailAdress: '',
    password: '',
    userId: '',
  });

  const navigate = useNavigate();

  const isValidSignIn = Object.values(loginData).every((o) => o !== '');

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginState(true);

    const { data } = await api.loginAsAdmin(loginData);

    if (data.status === 'error') {
      setError(true);
    } else if (isValidSignIn === true) {
      setError(false);

      localStorage.setItem('jwt', data.cookie);
      navigate(`/profile${queryParams}&loggedInUserId=${data.userId}`);
    }
  };

  const handleChangeSignIn = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const checkIsValid = (fieldName) => fieldName === '' || fieldName === undefined;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
      <DialogContent>
        <Container>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button onClick={handleClose} color="primary" style={{ float: 'right' }}>
                  <CloseIcon style={{ color: '#414141' }} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {t('sattler_text')}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <form onSubmit={handleLogin}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('login_form')}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="emailAdress"
                      style={{ width: '90%', marginLeft: 0 }}
                      label="Admin Email Adress"
                      variant="outlined"
                      size="small"
                      error={loginState ? checkIsValid(loginData.emailAdress) : ''}
                      helperText={loginState && checkIsValid(loginData.emailAdress) ? t('not_empty') : ''}
                      name="emailAdress"
                      className={classes.textField}
                      value={loginData.emailAdress}
                      onChange={handleChangeSignIn}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="password"
                      style={{ width: '90%', marginLeft: 0 }}
                      label={`Admin ${t('password')}`}
                      name="password"
                      variant="outlined"
                      className={classes.textField}
                      size="small"
                      type="password"
                      error={loginState ? checkIsValid(loginData.password) : ''}
                      helperText={loginState && checkIsValid(loginData.password) ? t('not_empty') : ''}
                      value={loginData.password}
                      onChange={handleChangeSignIn}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="userId"
                      style={{ width: '90%', marginLeft: 0 }}
                      label="Username"
                      name="userId"
                      variant="outlined"
                      className={classes.textField}
                      size="small"
                      type="text"
                      value={loginData.userId}
                      onChange={handleChangeSignIn}
                    />
                  </Grid>
                  {loginError && (
                    <Grid item xs={12}>
                      <div
                        style={{
                          width: '90%',
                          color: 'red',
                          fontWeight: 700,
                        }}
                      >
                        {t('wrong_username')}
                      </div>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      id="loginBtn"
                      type="submit"
                      style={{
                        float: 'right',
                        marginRight: 60,
                        marginTop: 10,
                        marginBottom: 10,
                        fontSize: '20px',
                      }}
                    >
                      {t('login')}
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
