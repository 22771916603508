import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useData } from '../DataProvider';
import {
  getCollectionById,
  getDesignlineById,
  getHotspotIndex,
  getPictogramSrcByProductSubgroupId,
  getSelectedItemById,
  replaceSpecialChars,
} from '../../util/util';
import ShareDialog from '../ShareDialog/ShareDialog';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    textAlign: 'left',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '100%',
    height: '180px',
  },
  row: {
    display: 'flex',
  },
  col: {
    flex: 1,
  },
  small: {
    fontSize: '11px',
  },
  card: {
    minWidth: '50vw',
    padding: '15px',
    background: '#F8F8F8',
    marginBottom: '15px',
  },
}));

export default function FabricItemDetailsTab({ cardItem, isArticleSearch, hotspotId, isGardenFurniture }) {
  const classes = useStyles();
  const {
    jsonData,
    setSelectedItems,
    selectedSearchItems,
    selectedSceneIndex,
    setSelectedItemsPerIndex,
    selectedItemsPerIndex,
    allScenes,
  } = useData();

  const fabricNameItem = replaceSpecialChars(cardItem.fabric.post_title);
  const src = `${process.env.REACT_APP_BASE_URL}/wp-content/uploads/stoffdetailansicht/${fabricNameItem}/hd.jpg`;

  const { selectColor, watchMap, updateCard } = useData();
  const isOnWatchMap = !!watchMap.get(cardItem.fabric.id);

  const [isFavorite, setFavorite] = useState(isOnWatchMap);
  const [title, setTitle] = useState('');

  const setItemFavorite = () => {
    setFavorite(!isFavorite);

    const newCardInfo = {
      subgroupId: hotspotId,
      isFavorite: !isFavorite,
      product: cardItem.fabric,
    };
    updateCard(cardItem.fabric.id, newCardInfo);
  };

  const fabricNumber =
    cardItem.fabric.customer_set_alt_article_number && cardItem.fabric.customer_set_alt_article_number.length > 3
      ? cardItem.fabric.customer_set_alt_article_number
      : cardItem.fabric.post_title;

  const fabricTitle =
    cardItem.fabric.customer_set_alt_fabric_name !== ''
      ? cardItem.fabric.customer_set_alt_fabric_name
      : cardItem.fabric.fabric_name;

  const concatFabricNumber =
    fabricNumber.length === 6 ? `${fabricNumber.substring(0, 3)} ${fabricNumber.substring(3, 6)}` : fabricNumber;

  useEffect(() => {
    if (isGardenFurniture) {
      const collections = cardItem.fabric.collection
        .split(',')
        .map((item) => getCollectionById(item, jsonData).post_title)
        .join(', ');
      setTitle(collections);
    } else {
      const text = `${getCollectionById(cardItem.fabric.collection, jsonData).post_title}
    ${cardItem.fabric.design_line !== '' ? getDesignlineById(cardItem.fabric.design_line, jsonData).post_title : ''}`;
      setTitle(text);
    }
  }, [cardItem.fabric.collection, cardItem.fabric.design_line, isGardenFurniture, jsonData]);

  const onClose = () => {
    if (isArticleSearch) {
      const scene = allScenes[selectedSceneIndex];
      if (Object.values(scene.fabrics).includes(cardItem.fabric.id)) {
        scene.scene.hotspots.forEach((hotspot) => {
          if (cardItem.fabric.product_subgroup.includes(hotspot.product_group)) {
            getSelectedItemById(selectedSearchItems, hotspot.product_group).fabric = cardItem.fabric;
            setSelectedItems({ ...selectedSearchItems });
            const hotspotIndex = getHotspotIndex(hotspot);

            selectedItemsPerIndex[hotspotIndex].fabric = cardItem.fabric;
            selectedItemsPerIndex[hotspotIndex].subgroupId = hotspot.product_group;
            setSelectedItemsPerIndex({ ...selectedItemsPerIndex });
          }
        });
      }
    }
  };
  return (
    <Card className={classes.card}>
      <Grid container className={classes.root}>
        <Grid
          md={2}
          xs={4}
          justify="left"
          style={isArticleSearch ? { cursor: 'pointer', textDecoration: 'underline' } : null}
          onClick={onClose}
        >
          <Typography component="h6" variant="h6">
            {concatFabricNumber}
          </Typography>
        </Grid>
        <Grid md={2} xs={6}>
          <Typography variant="subtitle1">{fabricTitle}</Typography>
        </Grid>

        <Grid md={2} xs={6} justify="left">
          <Typography variant="caption" color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid md={4} xs={6}>
          <img
            src={getPictogramSrcByProductSubgroupId(cardItem.subgroupId, jsonData)}
            alt="Piktogramm"
            style={{ width: 30 }}
          />
        </Grid>
      </Grid>

      <div className={classes.root}>
        <CardMedia
          className={classes.cover}
          style={isArticleSearch ? { cursor: 'pointer' } : null}
          image={src}
          title={cardItem.fabric.post_title}
          onClick={onClose}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="button" onClick={() => {}}>
          <ShareDialog cardItem={cardItem.fabric} />
        </Button>
        <IconButton aria-label="like" style={{ color: selectColor }} onClick={setItemFavorite}>
          {isFavorite ? (
            <FavoriteIcon style={{ color: selectColor }} />
          ) : (
            <FavoriteBorderIcon style={{ color: selectColor }} />
          )}
        </IconButton>
      </div>
    </Card>
  );
}

FabricItemDetailsTab.propTypes = {
  cardItem: PropTypes.instanceOf(Object).isRequired,
};
