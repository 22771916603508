import Container from '@material-ui/core/Container';
import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactCompareSlider } from 'react-compare-slider';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../components/navigation/NavigationBar';
import RegisterDialog from '../components/FabricDialog/RegisterDialog';
import LayeringComponent from '../components/LayeringComponent';
import { useData } from '../components/DataProvider';
import FabricViewDialog from '../components/FabricDialog/FabricViewDialog';
import { getHotspotIndex } from '../util/util';
import PreviewButtons from '../components/Carousel/PreviewButtons';
import MiniView from '../components/Carousel/MiniView/MiniView';

export default function SceneComparePage() {
  const {
    selectedSceneIndex,
    wallColor,
    wallColorRight,
    jsonData,
    selectedItems,
    selectedItemsPerIndex,
    selectedItemsPerIndexRight,
    selectedItemsRight,
    hasBanner,
    hasPrivacyPolicy,
    allScenes,
  } = useData();

  const screenHeight = window.innerHeight - 74;
  const targetRef = useRef();
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
  const { t } = useTranslation();
  const isPhone = useMediaQuery({ maxWidth: 699, orientation: 'portrait' });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isTabletPortrait = useMediaQuery({ minWidth: 700, maxWidth: 1250, orientation: 'portrait' });

  const [showMiniViewLeft, setShowMiniViewLeft] = useState(false);
  const [showMiniViewRight, setShowMiniViewRight] = useState(false);
  const [miniViewHotspotIndexLeft, setMiniViewHotspotIndexLeft] = useState(false);
  const [miniViewHotspotIndexRight, setMiniViewHotspotIndexRight] = useState(false);
  const [miniViewHotspotIdLeft, setMiniViewHotspotIdLeft] = useState(false);
  const [miniViewHotspotIdRight, setMiniViewHotspotIdRight] = useState(false);
  let imageHeight = 700;

  if (isPhone && isPortrait) {
    imageHeight = 360;
  } else if (isTabletPortrait) {
    imageHeight = screenHeight > 700 ? 700 : screenHeight;
  }

  const fullScreenStyles = {
    height: screenHeight,
  };

  const croppedStyles = {
    width: '100%',
    height: imageHeight,
    objectFit: 'cover',
  };

  const layeringStyle = {
    position: 'absolute',
    height: imageHeight,
    ...croppedStyles,
  };

  const navContainerStyles = {
    position: 'absolute',
    bottom: 5,
    left: 0,
    width: '100%',
    zIndex: 100,
  };

  const miniViewStyles = {
    position: 'absolute',
    bottom: 100,
    left: 0,
    width: '100%',
    backgroundColor: 'transparent',
    opacity: 1,
    height: 100,
  };

  const imageButtonStylesLowLeft = {
    position: 'absolute',
    bottom: isPortrait ? 10 : 0,
    left: 15,
    width: '50%',
    backgroundColor: 'transparent',
    opacity: 1,
    height: '36px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'noWrap',
  };

  const imageButtonStylesLowRight = {
    position: 'absolute',
    bottom: isPortrait ? 10 : 0,
    left: 0,
    width: '100%',
    backgroundColor: 'transparent',
    opacity: 1,
    height: '36px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'noWrap',
  };

  const imageButtonStylesHighLeft = {
    ...imageButtonStylesLowLeft,
    bottom: 200,
  };

  const imageButtonStylesHighRight = {
    ...imageButtonStylesLowRight,
    bottom: 200,
  };

  const currentScene = allScenes[selectedSceneIndex];

  const leftImage = (
    <LayeringComponent
      imageRef={null}
      index={selectedSceneIndex}
      sceneItem={currentScene}
      isFullScreen={false}
      isPortrait={isPortrait}
      fullScreenStyles={fullScreenStyles}
      layeringStyle={layeringStyle}
      selectedItems={selectedItems}
      selectedItemsPerIndex={selectedItemsPerIndex}
      wallColor={wallColor}
      jsonData={jsonData}
      opacity={5}
    />
  );

  const rightImage = (
    <LayeringComponent
      imageRef={null}
      index={selectedSceneIndex}
      sceneItem={currentScene}
      isFullScreen={false}
      isPortrait={isPortrait}
      fullScreenStyles={fullScreenStyles}
      layeringStyle={layeringStyle}
      selectedItems={selectedItemsRight}
      selectedItemsPerIndex={selectedItemsPerIndexRight}
      wallColor={wallColorRight}
      jsonData={jsonData}
      right
    />
  );

  const imageSliderStylesLandscape = {
    height: imageHeight,
    position: 'relative',
  };

  function openMiniViewLeft(index, productGroup) {
    if (!isPhone) setShowMiniViewLeft(true);
    setMiniViewHotspotIndexLeft(index);
    setMiniViewHotspotIdLeft(productGroup);
  }

  function openMiniViewRight(index, productGroup) {
    if (!isPhone) setShowMiniViewRight(true);
    setMiniViewHotspotIndexRight(index);
    setMiniViewHotspotIdRight(productGroup);
  }

  function closeMiniView() {
    setShowMiniViewLeft(false);
    setShowMiniViewRight(false);
  }

  return (
    <Container disableGutters={isTablet} ref={targetRef}>
      <NavigationBar />

      <div style={imageSliderStylesLandscape}>
        <ReactCompareSlider itemOne={leftImage} itemTwo={rightImage} style={imageSliderStylesLandscape} />

        {allScenes[selectedSceneIndex].scene.hotspots.map(
          (hotspot) =>
            hotspot.product_group !== '7318' && (
              <FabricViewDialog
                hotspotId={hotspot.product_group}
                hotspotIndex={getHotspotIndex(hotspot)}
                key={hotspot.product_group}
                left={hotspot.x}
                top={hotspot.y}
                orientation={hotspot.orientation}
                isCompareMode
                productGroupId={hotspot.product_group}
              />
            ),
        )}

        {!showMiniViewRight && (
          <div style={{ ...navContainerStyles, ...{ zIndex: showMiniViewRight ? 10 : 101 } }}>
            <div style={showMiniViewLeft ? imageButtonStylesHighLeft : imageButtonStylesLowLeft}>
              <div>
                {isPhone ? null : (
                  <PreviewButtons
                    showMiniView={openMiniViewLeft}
                    selectedItems={selectedItemsPerIndex}
                    items={selectedItems}
                    position="left"
                  />
                )}
              </div>
            </div>
            {showMiniViewLeft ? (
              <div style={miniViewStyles}>
                <MiniView
                  hotspotIndex={miniViewHotspotIndexLeft}
                  hotspotId={miniViewHotspotIdLeft}
                  closeMiniView={closeMiniView}
                  direction="left"
                />
              </div>
            ) : null}
          </div>
        )}

        {!showMiniViewLeft && (
          <div style={{ ...navContainerStyles, ...{ zIndex: showMiniViewLeft ? 10 : 100 } }}>
            <div style={showMiniViewRight ? imageButtonStylesHighRight : imageButtonStylesLowRight}>
              <div>
                {isPhone ? null : (
                  <PreviewButtons
                    showMiniView={openMiniViewRight}
                    position="right"
                    selectedItems={selectedItemsPerIndexRight}
                    items={selectedItemsRight}
                  />
                )}
              </div>
            </div>
            {showMiniViewRight ? (
              <div style={miniViewStyles}>
                <MiniView
                  hotspotIndex={miniViewHotspotIndexRight}
                  hotspotId={miniViewHotspotIdRight}
                  closeMiniView={closeMiniView}
                  direction="right"
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
      {hasPrivacyPolicy && (
        <Grid container style={{ fontSize: '12px', color: '#414141', padding: '5px' }}>
          <Grid xs={12} item md={9}>
            {t('infotext')}
            <br />
          </Grid>
        </Grid>
      )}
      {!isMobileScreen && hasBanner && <RegisterDialog margin={0} />}
    </Container>
  );
}
