import FormData from 'form-data';
import api from '../api/api';

export const getDesignlineById = (designId, data) => data.design_lines.find((item) => item.id.toString() === designId);

export const saveSettings = async (userSelection, userConfig) => {
  const sceneIds = Object.entries(userSelection.selectedScenes)
    .map((el) => el[1].filter((el2) => el2.isChecked === true))
    .flat()
    .flatMap((el) => el.id)
    .join();

  const formData = new FormData();
  formData.append('user_id', userConfig.userId);
  formData.append('application_enable_contact_email', userSelection.enableApplicationEmail);
  formData.append('application_contact_email', userSelection.applicationContactEmail);
  formData.append('highlightcolor', userSelection.highlightColor);
  formData.append('show_embedding_banner', userSelection.showEmbedBanner);
  formData.append('whitelabelapptext', userSelection.whitelabelAppText);
  formData.append('white_label_logo', userSelection.whiteLabelAppLogo);
  formData.append('customer_scenes', sceneIds);
  formData.append('custom_application_width_type', userSelection.iframeWidth);
  formData.append('parentUserId', userConfig.parentUserId);
  formData.append('userlng', userSelection.lang);

  const response = await api.postUserData(formData);
  return response.status === 200;
};
