import Container from '@material-ui/core/Container';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../components/navigation/NavigationBar';
import CarouselComponent from '../components/Carousel/CarouselComponent';
import { useData } from '../components/DataProvider';
import RegisterDialog from '../components/FabricDialog/RegisterDialog';
import PreviewButtons from '../components/Carousel/PreviewButtons';
import MobileMiniView from '../components/Carousel/MiniView/MobileMiniView';
import { getTriggerFabricDetailsDialog } from '../components/Subjects/subjects';
import FabricDetailsDialog from '../components/Carousel/MiniView/dialog/FabricDetailsDialog';
import FooterCarousel from '../components/Carousel/FooterCarousel';
import MiniView from '../components/Carousel/MiniView/MiniView';
import Thumbs from '../components/Carousel/Thumbs/Thumbs';
import AdminDialog from '../components/FabricDialog/AdminDialog';

export default function MainPage() {
  const { isFullScreen, selectedItemsPerIndex, setLoading, isLoading, hasBanner, userId, selectedItems } = useData();
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const [removePadding, setRemovePadding] = useState(false);
  const [maxWidth, setMaxWidth] = useState('lg');
  const isMobileScreen = useMediaQuery({ maxWidth: 850 });
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showThumbs, setShowThumbs] = useState(false);
  const [showMiniView, setMiniView] = useState(false);
  const [openMiniScenes, setOpenMiniScenes] = useState(false);
  const [miniViewHotspotIndex, setMiniViewHotspotIndex] = useState(0);
  const [miniViewHotspotId, setMiniViewHotspotId] = useState(0);
  const { t } = useTranslation();
  const language = new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng');

  getTriggerFabricDetailsDialog().subscribe((val) => {
    setShowDetailsDialog(val);
  });

  function toggleThumbView() {
    setShowThumbs(!showThumbs);
    setMiniView(false);
  }

  function openMiniView(index, productGroup) {
    setMiniView(true);
    setShowThumbs(false);
    setMiniViewHotspotIndex(index);
    setMiniViewHotspotId(productGroup);
  }

  function closeMiniView() {
    setMiniView(false);
  }

  function closeThumbs() {
    setShowThumbs(false);
  }

  useEffect(() => {
    setRemovePadding(isFullScreen || isMobileScreen);
    setMaxWidth(isFullScreen ? false : 'lg');
  }, [isFullScreen, isMobileScreen, setLoading]);

  return (
    <Container disableGutters={removePadding} maxWidth={maxWidth} style={isLoading ? { display: 'none' } : null}>
      <div>
        <NavigationBar />
        <div style={isMobileScreen && isLandscape ? { padding: '0 50px 0 50px' } : null}>
          <CarouselComponent
            closeThumbs={closeThumbs}
            closeMiniView={closeMiniView}
            openMiniView={openMiniView}
            showThumbs={showThumbs}
            toggleThumbView={toggleThumbView}
            showMiniView={showMiniView}
            miniViewHotspotIndex={miniViewHotspotIndex}
            miniViewHotspotId={miniViewHotspotId}
          />
          {isPortrait && (
            <div>
              <PreviewButtons
                showMiniView={openMiniView}
                selectedItems={selectedItemsPerIndex}
                position="left"
                items={selectedItems}
                styles={{
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
              {!isMobileScreen && showMiniView && (
                <MiniView
                  hotspotIndex={miniViewHotspotIndex}
                  hotspotId={miniViewHotspotId}
                  closeMiniView={closeMiniView}
                />
              )}
              {isMobileScreen && showMiniView && (
                <MobileMiniView
                  hotspotIndex={miniViewHotspotIndex}
                  hotspotId={miniViewHotspotId}
                  closeMiniView={closeMiniView}
                />
              )}
              {openMiniScenes && <Thumbs close={() => setOpenMiniScenes(!openMiniScenes)} />}

              <FooterCarousel
                showThumbs={(val) => setOpenMiniScenes(val)}
                openMiniView={openMiniView}
                selectedItems={selectedItemsPerIndex}
              />
            </div>
          )}
          {!isFullScreen && (
            <Grid container style={{ fontSize: '12px', color: '#414141', padding: '10px' }}>
              <Grid xs={12} item md={9}>
                {t('infotext')}
              </Grid>
              {userId && userId === '1108' && (
                <Grid xs={12} item md={3}>
                  <Link
                    target="blank_"
                    style={{ color: '#414141', float: 'right' }}
                    href={`${process.env.REACT_APP_BASE_URL}/datenschutzerklaerung?lang=${language}`}
                  >
                    {t('data_privacy_word')}
                  </Link>
                </Grid>
              )}
            </Grid>
          )}
          {!isMobileScreen && !isFullScreen && hasBanner && <RegisterDialog margin={0} />}
        </div>
        {showDetailsDialog && (
          <FabricDetailsDialog
            closeMiniView={() => closeMiniView()}
            onClose={() => setShowDetailsDialog(false)}
            isArticleSearch
          />
        )}
        <AdminDialog />
      </div>
    </Container>
  );
}
