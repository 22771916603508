import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import { getAttributeById } from '../../util/util';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    display: 'contents',
  },
}));

export default function SpecialPropertiesAccordion({ expandedId, setExpandedId, possibleAttributeSets }) {
  const classes = useStyles();
  const { jsonData, filterItems, setFilterItems } = useData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [countElements, setCountElements] = useState(0);
  const { specialAttributesFilter } = filterItems;
  const id = 2;
  const { t } = useTranslation();

  const selectAttribute = (attribute) => {
    if (specialAttributesFilter.includes(attribute.toString())) {
      filterItems.specialAttributesFilter = specialAttributesFilter.filter((c) => c !== attribute.toString());
      setFilterItems({ ...filterItems });
    } else {
      specialAttributesFilter.push(attribute.toString());
      filterItems.specialAttributesFilter = specialAttributesFilter;
      setFilterItems({ ...filterItems });
    }
    setCountElements(specialAttributesFilter.length);
  };

  const isSpecialAttributesChecked = (attribute) => specialAttributesFilter.includes(attribute.toString());

  function handleOnChange(expanded) {
    setIsExpanded(expanded);
    setExpandedId(id);
  }

  useEffect(() => {
    if (expandedId !== id) setIsExpanded(false);
    setCountElements(specialAttributesFilter.length);
  }, [expandedId, specialAttributesFilter]);

  return (
    <div className={classes.root}>
      <Accordion onChange={(event, expanded) => handleOnChange(expanded)} expanded={isExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>
            <Grid container>
              <Grid item xs={11}>
                {t('additional_props')}
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                ({countElements})
              </Grid>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} md={9}>
              <FormGroup row>
                <Grid container>
                  {Array.from(possibleAttributeSets).map((attributeId) => (
                    <Grid key={attributeId}>
                      <FormControlLabel
                        key={attributeId}
                        control={
                          <Checkbox
                            checked={isSpecialAttributesChecked(attributeId)}
                            onChange={() => selectAttribute(attributeId)}
                            name="checked"
                            color="primary"
                            key={attributeId}
                          />
                        }
                        label={attributeId ? getAttributeById(attributeId, jsonData).post_title : ''}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
