import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getColorById, getHotspotIndex, getSelectedItemById, replaceSpecialChars, useWindowSize } from '../util/util';
import { useData } from './DataProvider';

const LayeringComponent = ({
  index,
  sceneItem,
  isFullScreen,
  fullScreenStyles,
  layeringStyle,
  wallColor,
  selectedItemsPerIndex,
  jsonData,
  selectedItems,
  opacity,
  isCompareMode,
  right,
}) => {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const isPhoneLandScape = useMediaQuery({ maxWidth: 950, orientation: 'landscape' });
  const isPhonePortrait = useMediaQuery({ maxWidth: 699, orientation: 'portrait' });

  let height = 700;
  if (isPhoneLandScape && isLandscape) height = window.innerHeight - 70;
  else if (isPhonePortrait) height = 300;
  if (isFullScreen) height = 860;

  const srcSubPath = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/${sceneItem.scene.sub_path}/wall_matt.png`;
  const srcMain = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/${sceneItem.scene.sub_path}/main.png`;
  const srcWall = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}${sceneItem.scene.sub_path}wall_structure.png`;
  const errorSrc = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  const [loadedStateObj, setLoadedStateObj] = useState({
    img1: false,
    img2: false,
    img3: false,
    img4: false,
    img5: false,
  });

  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const {
    setLoading,
    setImageDimensions,
    setSelectedItemsPerIndex,
    selectedItemsPerIndexRight,
    setSelectedItemsPerIndexRight,
    allScenes,
    selectedSceneIndex,
  } = useData();
  const containerRef = useRef(null);
  const size = useWindowSize();

  const carouselStyles = {
    position: 'relative',
    height,
    zIndex: opacity,
  };

  const visualStyles = {
    display: 'none',
  };

  useEffect(() => {
    const indexedItems = [];
    allScenes[selectedSceneIndex].scene.hotspots.forEach((hotspot) => {
      const currentIndex = getHotspotIndex(hotspot);

      if (hotspot.product_group !== '7318' && jsonData.fabrics[hotspot.product_group]) {
        if (
          jsonData.fabrics[hotspot.product_group].filter(
            (elem) => elem.post_title === selectedItemsPerIndex[currentIndex].fabric.post_title,
          ).length === 0
        ) {
          indexedItems[currentIndex] = getSelectedItemById(selectedItems, hotspot.product_group);
          if (right) {
            setSelectedItemsPerIndexRight({ ...indexedItems });
          } else {
            setSelectedItemsPerIndex({ ...indexedItems });
          }
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData, right, sceneItem, sceneItem.scene.hotspots, selectedSceneIndex]);

  const getFabricFromSelectedItems = (hotspot) => {
    // TODO: [SDS-261] move setstate to useEffect
    const currentIndex = getHotspotIndex(hotspot);
    const indexedItems = selectedItemsPerIndex;

    if (!selectedItemsPerIndex[currentIndex]) {
      indexedItems[currentIndex] = getSelectedItemById(selectedItems, hotspot.product_group);

      if (indexedItems[currentIndex].fabric && indexedItems[currentIndex].fabric.post_title) {
        const fabricNameItem = replaceSpecialChars(indexedItems[currentIndex].fabric.post_title);
        return fabricNameItem;
      }
    }

    if (selectedItemsPerIndex[currentIndex].fabric && selectedItemsPerIndex[currentIndex].fabric.post_title) {
      const fabricNameItem = replaceSpecialChars(selectedItemsPerIndex[currentIndex].fabric.post_title);
      return fabricNameItem;
    }
    return selectedItemsPerIndex[currentIndex].fabric.post_title;
  };

  const handleChange = (prop, value) => {
    setLoadedStateObj({ ...loadedStateObj, [prop]: value });
  };

  useEffect(() => {
    if (Object.entries(loadedStateObj).every((entry) => entry[1] === true)) {
      setAllImagesLoaded(true);
      setLoading(false);
    }
  }, [isCompareMode, loadedStateObj, selectedItemsPerIndex, selectedItemsPerIndexRight.length, setLoading]);

  useEffect(() => {
    const block = containerRef.current.getBoundingClientRect();
    if (block !== undefined) {
      setImageDimensions({
        width: +block.width,
        height: +block.height,
      });
    }
  }, [allImagesLoaded, setImageDimensions, size]);

  return (
    <div style={allImagesLoaded ? {} : visualStyles}>
      <div style={carouselStyles} id="imageContainer" className="imageCointainer">
        <img
          ref={containerRef}
          id={`image_ ${index}`}
          src={srcSubPath}
          alt="Zeigt ein Bild an."
          onError={(e) => {
            e.target.src = errorSrc;
          }}
          style={{
            ...(isFullScreen ? fullScreenStyles : layeringStyle),
            background: wallColor.hex,
          }}
          onLoad={() => handleChange('img1', true)}
        />

        <img
          id={`image_ ${index}`}
          src={srcMain}
          alt="Zeigt ein Bild an."
          style={{
            ...(isFullScreen ? fullScreenStyles : layeringStyle),
          }}
          onLoad={() => handleChange('img2', true)}
        />

        {sceneItem.scene.hotspots &&
          sceneItem.scene.hotspots.map((hotspot) => {
            const srcFabric =
              hotspot.product_group !== '7318'
                ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}${
                    sceneItem.scene.sub_path
                  }${hotspot.subfolder}/${sceneItem.scene.sub_path.split('/')[2]}_${
                    hotspot.subfolder
                  }_${getFabricFromSelectedItems(hotspot)}.png`
                : '';
            return hotspot.product_group !== '7318' ? (
              <div key={hotspot.product_group}>
                <img
                  id={`image_ ${index}`}
                  src={srcFabric}
                  alt="Zeigt ein Bild an."
                  onError={(e) => {
                    e.target.src = errorSrc;
                  }}
                  style={{
                    ...(isFullScreen ? fullScreenStyles : layeringStyle),
                  }}
                  onLoad={() => handleChange('img3', true)}
                />
              </div>
            ) : (
              <div key={hotspot.product_group}> </div>
            );
          })}

        <img
          id={`image_ ${index}`}
          src={srcWall}
          alt="Zeigt ein Bild an."
          style={{
            ...(isFullScreen ? fullScreenStyles : layeringStyle),
          }}
          onError={(e) => {
            e.target.src = errorSrc;
          }}
          onLoad={() => handleChange('img4', true)}
        />
        <img
          id={`image_ ${index}`}
          src={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/${
            sceneItem.scene.sub_path
          }${sceneItem.scene.post_title}_05_${
            getColorById(selectedItemsPerIndex[0].fabric.color_primary, jsonData).post_title_de
          }.png`}
          alt="Zeigt ein Bild an."
          onError={(e) => {
            e.target.src = errorSrc;
          }}
          style={{
            ...(isFullScreen ? fullScreenStyles : layeringStyle),
            opacity: 0.3,
          }}
          onLoad={() => handleChange('img5', true)}
        />
      </div>
    </div>
  );
};

export default LayeringComponent;
