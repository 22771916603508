import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import FabricWatchList from './FabricWatchList';
import { maxWidthTablet, minWidthDesktop, minWidthTablet } from '../../util/util';

const useStyles = makeStyles({
  customTextField: {
    '& input::placeholder': {
      fontSize: '10px',
    },
  },
});

const ValidatedTextField = ({ isInvalid, onSubmitted, ...props }) => {
  const { helperText, value } = props;
  let invalid = isInvalid(value);
  if (onSubmitted) {
    invalid = false;
  }

  return <TextField {...props} error={Boolean(invalid)} helperText={invalid || helperText} />;
};

const ValidatedTextMultiTextField = ({ isInvalid, onSubmitted, ...props }) => {
  const { helperText, value } = props;
  let invalid = isInvalid(value);
  const isPhoneHigh = useMediaQuery({ minWidth: 699, maxWidth: minWidthTablet });

  const classes = useStyles();

  if (onSubmitted) {
    invalid = false;
  }

  return (
    <TextField
      multiline
      maxLength="500"
      style={{ fontSize: isPhoneHigh ? 10 : 12 }}
      rows={4}
      InputProps={{
        classes: { input: classes.customTextField },
      }}
      {...props}
      error={Boolean(invalid)}
      helperText={invalid || helperText}
    />
  );
};

ValidatedTextField.propTypes = {
  isInvalid: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onSubmitted: PropTypes.bool.isRequired,
};

const CustomerInquiryForm = forwardRef((props, ref) => {
  const isDesktop = useMediaQuery({ minWidth: minWidthDesktop });
  const isTabletHigh = useMediaQuery({ minWidth: 800, maxWidth: maxWidthTablet });
  const isTabletLow = useMediaQuery({ minWidth: minWidthTablet, maxWidth: 800 });
  const isPhoneHigh = useMediaQuery({ minWidth: 699, maxWidth: minWidthTablet });
  const isPhoneLow = useMediaQuery({ minWidth: 0, maxWidth: 699 });
  const { t } = useTranslation();

  let numberOfTodos;
  if (isDesktop) {
    numberOfTodos = 6;
  } else if (isTabletHigh) {
    numberOfTodos = 5;
  } else if (isTabletLow) {
    numberOfTodos = 4;
  } else if (isPhoneHigh) {
    numberOfTodos = 3;
  } else if (isPhoneLow) {
    numberOfTodos = 2;
  }
  const { cardId, onSubmit } = props;
  const [notSubmitted, setSubmitted] = useState(true);

  /* const getCurrentLink = () => {
    if (localURI) {
      return `${localURI}?userId=${userId}&hs1=${selectedItems[0].fabric.post_title}&hs2=${selectedItems[1].fabric.post_title}&hs3=${selectedItems[2].fabric.post_title}&scene=${selectedSceneIndex}`;
    }
    return `${window.location.href}?userId=${userId}&hs1=${selectedItems[0].fabric.post_title}&hs2=${selectedItems[1].fabric.post_title}&hs3=${selectedItems[2].fabric.post_title}&scene=${selectedSceneIndex}`;
  }; */
  const [inputs, setInputs] = useState([
    {
      id: 'firstName',
      label: t('firstname'),
      placeholder: t('enter_firstname'),
      value: '',
      fullWidth: true,
      valid: false,
      isInvalid: (value) => (value !== '' ? false : t('not_empty')),
    },
    {
      id: 'lastName',
      label: t('lastname'),
      placeholder: t('enter_lastname'),
      value: '',
      fullWidth: true,
      valid: false,
      isInvalid: (value) => (value !== '' ? false : t('not_empty')),
    },
    {
      id: 'regarding',
      label: t('enter_subject'),
      placeholder: t('enter_subject'),
      value: t('fabricrequest'),
      fullWidth: true,
      valid: true,
      isInvalid: (value) => (value !== '' ? false : t('not_empty')),
    },
    {
      id: 'note',
      label: t('note'),
      placeholder: t('enter_note'),
      value: '',
      multiLine: true,
      rows: 4,
      fullWidth: true,
      valid: false,
      isInvalid: (value) => (value !== '' ? false : t('not_empty')),
    },
  ]);

  function allFieldsValid() {
    for (let index = 0; index < inputs.length; index += 1) {
      if (!inputs[index].valid) return false;
    }
    return true;
  }

  useImperativeHandle(ref, () => ({
    submit() {
      setSubmitted(false);
      if (allFieldsValid()) onSubmit(inputs);
    },
  }));

  const divStyles = {
    gap: 2,
    color: '#707070',
  };

  const onChange = ({ target: { id, value } }) => {
    const newInputs = [...inputs];
    const index = inputs.findIndex((input) => input.id === id);

    const { isInvalid } = newInputs[index];

    newInputs[index] = {
      ...inputs[index],
      value,
      valid: !isInvalid(value),
    };

    setInputs(newInputs);
  };

  return (
    <>
      <form action="">
        <fieldset style={{ borderRadius: 10, gap: 8, display: 'flex', flexDirection: 'column' }}>
          <div style={divStyles}>{t('recipient')}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <ValidatedTextField
              {...inputs[0]}
              onChange={onChange}
              onSubmitted={notSubmitted}
              style={{ marginRight: '30px' }}
            />
            <ValidatedTextField {...inputs[1]} onChange={onChange} onSubmitted={notSubmitted} />
          </div>
          <ValidatedTextField {...inputs[2]} onChange={onChange} onSubmitted={notSubmitted} />
          <ValidatedTextMultiTextField {...inputs[3]} onChange={onChange} onSubmitted={notSubmitted} />
          <p style={divStyles}>{t('fabric_request')}</p>
          <FabricWatchList numberOfElements={numberOfTodos} cardId={cardId} />
          {/* <FormGroup row style={{ marginTop: 30 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyPolicyState}
                  onChange={(event) => setPrivacyPolicyState(event.target.checked)}
                  name="privacy_policy_checkbox"
                  color="primary"
                  required
                />
              }
              label={
                <Trans
                  i18nKey="data_privacy"
                  t={t}
                  components={[
                    <a href={`${process.env.REACT_APP_BASE_URL}/datenschutzerklaerung?lang=${language}`}>
                      Profildaten
                    </a>,
                  ]}
                />
              }
            />
          </FormGroup> */}
        </fieldset>
      </form>
    </>
  );
});

export default CustomerInquiryForm;
