import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Divider, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QualityAccordion from './QualityAccordion';
import DesignAccordion from './DesignAccordion';
import SpecialPropertiesAccordion from './SpecialPropertiesAccordion';
import { useData } from '../DataProvider';
import {
  getProductGroupByOriginalId,
  IsPhoneLandScape,
  IsPhonePortrait,
  IsTabletLandScape,
  IsTabletPortrait,
} from '../../util/util';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    display: 'contents',
  },
}));

export default function FilterOptionsAccordion({
  numberItems,
  updateNumberOfElements,
  expandedId,
  setExpandedId,
  hotspotId,
}) {
  const classes = useStyles();
  const { filterItems, setFilterItems, jsonData, currentScene, cardsMap } = useData();
  // const [shouldResetFilter, setShouldResetFilter] = useState(resetFilter);
  const [isExpanded, setIsExpanded] = useState(false);
  const [designCount, setDesignCount] = useState(0);
  const isPhonePortrait = IsPhonePortrait();
  const isPhoneLandScape = IsPhoneLandScape();
  const isTabletLandScape = IsTabletLandScape();
  const isTabletPortrait = IsTabletPortrait();
  const { t } = useTranslation();

  let allProducts = Object.values(currentScene.fabrics).map(
    (s) =>
      cardsMap
        .filter((elem) => s === elem.product.id)
        .filter((elem) => (hotspotId && hotspotId !== '0' ? elem.subgroupId === hotspotId : false))[0],
  );

  allProducts = allProducts.filter((elem) => elem !== undefined);

  const possibleAttributeSets =
    [
      ...new Set(
        allProducts
          .map((elem) =>
            elem.product.attribute_set
              .split(',')
              .filter((elem1) => elem1 !== '')
              .filter((el) => el.length > 0),
          )
          .flat(1),
      ),
    ] || [];

  const isFilterSet = (items) =>
    items.colorFilter.length > 0 ||
    items.collectionFilter.length > 0 ||
    items.specialAttributesFilter.length > 0 ||
    items.designFilter.length > 0 ||
    items.patternFilter.length > 0;

  const handleFilterReset = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetAllFilter();
  };

  const resetAllFilter = () => {
    const items = filterItems;
    items.colorFilter = [];
    items.patternFilter = [];
    items.specialAttributesFilter = [];
    items.collectionFilter = [];
    items.designFilter = [];
    setFilterItems({ ...items });
    setDesignCount(0);
  };

  /* useEffect(() => {
    const resetAllFilter = () => {
      const items = filterItems;
      items.colorFilter = [];
      items.patternFilter = [];
      items.specialAttributesFilter = [];
      items.collectionFilter = [];
      items.designFilter = [];
      setFilterItems({ ...items });
      setDesignCount(0);
    };

    if (resetFilter || shouldResetFilter) {
      resetAllFilter();
      setShouldResetFilter(false);
    }
  }, [filterItems, resetFilter, setFilterItems, shouldResetFilter]); */

  function setNumberOfElements(expanded) {
    if (expanded) updateNumberOfElements(numberItems / 2);
    else if (isTabletLandScape) updateNumberOfElements(12);
    else if (isTabletPortrait) updateNumberOfElements(8);
    else if (isPhoneLandScape) updateNumberOfElements(8);
    else if (isPhonePortrait) updateNumberOfElements(4);
    else updateNumberOfElements(10);
  }

  const productGroup = getProductGroupByOriginalId(currentScene.product_group, jsonData);

  const handleOnChange = (expanded) => {
    setNumberOfElements(expanded);
    setIsExpanded(expanded);
  };

  useEffect(() => {
    setIsExpanded(false);
  }, []);

  return (
    <div className={classes.root}>
      <Accordion onChange={(event, expanded) => handleOnChange(expanded)} expanded={isExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Grid container>
            <Grid item xs={9}>
              <Typography className={classes.heading}>{t('more_filters')}</Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'right' }}>
              {isFilterSet(filterItems) && (
                <Button
                  onClick={(e) => handleFilterReset(e)}
                  style={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                    textDecorationThickness: 'from-font',
                    fontSize: 10,
                  }}
                >
                  {t('reset_filter')}
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} md={12}>
              <QualityAccordion
                setDesignCount={(number) => setDesignCount(number)}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
              />
              <Divider light />
            </Grid>

            {(productGroup.isGardenFurniture === 'false' || !productGroup.isGardenFurniture) && (
              <Grid item xs={12} md={12}>
                <DesignAccordion designCount={designCount} expandedId={expandedId} setExpandedId={setExpandedId} />
                <Divider light />
              </Grid>
            )}
            {possibleAttributeSets && Array.from(possibleAttributeSets).length > 0 && (
              <Grid item xs={12} md={12}>
                <SpecialPropertiesAccordion
                  possibleAttributeSets={possibleAttributeSets}
                  expandedId={expandedId}
                  setExpandedId={setExpandedId}
                />
                <Divider light />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
